'use strict';

const selectors = {
    NOTIFICATION: 'js-alert',
    SUCCESS: 'alert-success',
    INFO: 'alert-info',
    ERROR: 'alert-danger',
    WARNING: 'alert-warning'
};

const cache = {
    $root: $('html'),
    $body: $('body'),
    $selector: $('.js-alert-wrapper')
};

/**
 * Creates notification message with an alert component.
 * @param {string} message - data message returned from the server's ajax call
 * @param {string} type - notification type which defines css class (default: '')
 * @param {Object} container - custom container to append notification (default: '.js-alert-wrapper')
 * @param {string} classCustom - css classname for custom styling (optional, default: '')
 * @param {function} callback - (optional)
 */

module.exports = {
    create: function (message, type, container, classCustom, callback) {
        var notification = '';
        var notificationClass = selectors[type] || '';
        var selector = cache.$selector;
        if (container) {
            selector = container;
        }
        var classExtra = classCustom || '';

        switch (type) {
            case 'success':
                notificationClass = selectors.SUCCESS;
                break;
            case 'info':
                notificationClass = selectors.INFO;
                break;
            case 'error':
                notificationClass = selectors.ERROR;
                break;
            case 'warning':
                notificationClass = selectors.WARNING;
                break;
            default:
                notificationClass = '';
        }

        notification =
            '<div class="alert ' +
            notificationClass +
            ' ' + selectors.NOTIFICATION +
            ' ' + classExtra +
            '" role="alert"><div class="alert__inner"><div class="alert__text"><span>' +
            message +
            '</span></div></div></div>';

        selector.find('.' + selectors.NOTIFICATION).remove();
        selector.append($(notification));

        $(cache.$root, cache.$body).animate({ scrollTop: 0 }, 500);

        if (callback) {
            callback();
        }
    }
};
