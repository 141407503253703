'use strict';

var formValidation = require('../components/formValidation');

var selectors = {
    reCaptchaTokenFieldNLSub: '.js-gCaptchaToken-nlsub',
    messageContainerSuccess: '.js-newsletter-message',
    messageContainerError: '.js-newsletter-invalid-feedback',
    mainContainer: '.js-newsletter-main',
    emailField: '#newsletter-form-email'
};

/**
 * Submits the newsletter form to back-end.
 * @param {*} $form jQuery object
 */
function submitNewsletterForm($form) {
    $.ajax({
        url: $form.attr('action'),
        type: 'post',
        dataType: 'json',
        data: $form.serialize(),
        success: function (data) {
            $form.spinner().stop();
            if (!data.success) {
                formValidation($form, data);
            } else if (data.success && $(selectors.mainContainer).length > 0) {
                // replaces main content with message
                $(selectors.mainContainer).html('<p>'+data.message+'</p>');
            } else {
                // show success message on form
                $(selectors.messageContainerSuccess).removeClass('d-none').text(data.message);

                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'alert.creation',
                    click: 'Créer votre alerte'
                });
            } 
        },
        error: function (err) {
            if (err.responseJSON.message) {
                // show error message on form
                $(selectors.emailField).addClass('is-invalid');
                $(selectors.messageContainerError).text(err.responseJSON.message);
            }
            $form.spinner().stop();
        }
    });
}

module.exports = {
    newsletterSubscribe: function () {
        $('form.newsletter-form').submit(function (e) {
            var $form = $(this);
            e.preventDefault();

            $form.spinner().start();

            // If Google reCaptcha is enabled/loaded
            if (window.grecaptcha) {
                // Google reCaptcha Site key
                var gsiteKey = $form.data('gsite-key');
                window.grecaptcha.ready(function () {
                    window.grecaptcha
                        .execute(gsiteKey, {action: $form.data('action')})
                        .then(function (token) {
                            $form.find(selectors.reCaptchaTokenFieldNLSub).val(token);
                            submitNewsletterForm($form);
                        });
                });
            } else {
                submitNewsletterForm($form);
            }

            return false;
        });
    }
};
