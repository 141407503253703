'use strict';

const cssConstants = require('../utils/cssConstants');
const notifications = require('./notifications');

/**
 * Remove all validation. Should be called every time before re-validating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearFormErrors(form) {
    $(form)
        .find('.form-control.' + cssConstants.INVALID +', .form-select.' + cssConstants.INVALID)
        .removeClass(cssConstants.INVALID);
    $(form).find('.form-group.' + cssConstants.FORM_GROUP_INVALID).removeClass(cssConstants.FORM_GROUP_INVALID);
}

module.exports = function (formElement, payload) {
    // clear form validation first
    clearFormErrors(formElement);

    if (typeof payload === 'object' && payload.fields) {
        Object.keys(payload.fields).forEach(function (key) {
            if (payload.fields[key]) {
                var formGroup = $(formElement).find('[name="' + key + '"]').parents('.form-group');
                var feedbackElement = formGroup.find('.invalid-feedback');

                if (feedbackElement.length > 0) {
                    if (Array.isArray(payload[key])) {
                        feedbackElement.html(payload.fields[key].join('<br/>'));
                    } else {
                        feedbackElement.html(payload.fields[key]);
                    }

                    feedbackElement.removeClass(cssConstants.VALID).addClass(cssConstants.INVALID);
                    formGroup.removeClass(cssConstants.VALID).addClass(cssConstants.FORM_GROUP_INVALID);
                    formGroup.find('.form-control, .form-select, .form-check-input').removeClass(cssConstants.VALID).addClass(cssConstants.INVALID);
                }
            }
        });
    }
    if (payload && payload.error) {
        var notificationContainer = $(formElement).find('.js-alert-wrapper');
        notifications.create(payload.errorMessage, 'error', notificationContainer);
    }
};
